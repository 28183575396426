//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'StageHomepage',
    components: { ImageCopyright },
    props: ['data'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
            header: null,
            timeout: 0,
            imagePath: this.$config.pimcoreUrl,
            videoPlayingStates: [],
            stageSwiper: null,
            activeSlideIndex: 0,
        };
    },
    computed: {
        items() {
            return this.data?.items?.map((item) => {
                return {
                    ...item,
                    headline: item.headline ? item.headline.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>') : '',
                    image: this.$mapImageData(item?.image?.image),
                    imageMobile: this.$mapImageData(item?.imageMobile?.image),
                    video: item?.video || null,
                };
            });
        },
    },
    mounted() {
        this.header = document.body.querySelector('.header');
        ['scroll', 'resize'].forEach((eventName) => {
            window.addEventListener(eventName, this.handleScrollPos);
        });
        this.handleScrollPos();

        // Initialize video states array
        if (this.items) {
            this.videoPlayingStates = new Array(this.items.length).fill(false);
        }

        // Initialize Swiper after the DOM has been updated
        this.$nextTick(() => {
            this.initSwiper();

            // Initialize videos after swiper is ready
            this.$nextTick(() => {
                this.initAllVideos();
            });
        });
    },
    methods: {
        initSwiper() {
            if (this.data.items.length <= 1) return;

            this.stageSwiper = new Swiper('.stage-homepage__slider-container--slide', {
                direction: 'horizontal',
                loop: false,
                speed: 750,
                pagination: {
                    el: '.stage-homepage__pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.stage-homepage__button--next',
                    prevEl: '.stage-homepage__button--prev',
                    disabledClass: 'stage-homepage__button--disabled',
                },
                on: {
                    slideChange: (swiper) => {
                        this.activeSlideIndex = swiper.activeIndex;
                        this.updateTabIndex();
                        this.handleSlideChange(swiper.activeIndex);
                    },
                    init: (swiper) => {
                        this.activeSlideIndex = swiper.activeIndex;
                        this.updateTabIndex();
                        // Initialize first slide video
                        this.playVideoOnSlide(0);
                    },
                },
            });
        },

        initAllVideos() {
            // Make sure all videos are loaded and ready
            this.items.forEach((item, index) => {
                if (item.video) {
                    const videoRef = this.getVideoElement(index);
                    if (videoRef) {
                        videoRef.addEventListener('loadeddata', () => {
                            console.log(`Video ${index} loaded`);
                            // Auto-play first slide video
                            if (index === 0) {
                                this.playVideoOnSlide(0);
                            }
                        });
                    }
                }
            });
        },

        getVideoElement(slideIndex) {
            const refName = `video-${slideIndex}`;
            return this.$refs[refName] ? this.$refs[refName][0] : null;
        },

        videoIsPlaying(slideIndex) {
            return this.videoPlayingStates[slideIndex] || false;
        },

        handleSlideChange(newIndex) {
            // Pause all videos except the active one
            this.items.forEach((item, index) => {
                if (item.video) {
                    if (index === newIndex) {
                        this.playVideoOnSlide(index);
                    } else {
                        this.pauseVideoOnSlide(index);
                    }
                }
            });
        },

        playVideoOnSlide(slideIndex) {
            const video = this.getVideoElement(slideIndex);
            if (!video) return;

            // Immediately set state to playing for UI feedback
            this.videoPlayingStates[slideIndex] = true;
            this.$forceUpdate();

            // Try to play the video
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        console.log(`Video ${slideIndex} playing`);
                    })
                    .catch((error) => {
                        console.error(`Error playing video ${slideIndex}:`, error);
                        this.videoPlayingStates[slideIndex] = false;
                        this.$forceUpdate();
                    });
            }
        },

        pauseVideoOnSlide(slideIndex) {
            const video = this.getVideoElement(slideIndex);
            if (!video) return;

            video.pause();
            this.videoPlayingStates[slideIndex] = false;
            this.$forceUpdate();
        },

        toggleVideo(slideIndex) {
            if (this.videoIsPlaying(slideIndex)) {
                this.pauseVideoOnSlide(slideIndex);
            } else {
                this.playVideoOnSlide(slideIndex);
            }
        },

        updateTabIndex() {
            const slides = this.$refs.stageSlide;
            if (!slides) return;

            slides.forEach((slide, index) => {
                const links = slide.querySelectorAll('a');
                links.forEach((link) => {
                    link.setAttribute('tabindex', index === this.activeSlideIndex ? '0' : '-1');
                });
            });
        },

        toContent() {
            let screenHeight = window.innerHeight;
            window.scrollTo({
                top: screenHeight,
                left: 0,
                behavior: 'smooth',
            });
        },

        handleScrollPos() {
            if (window.scrollY > 1) {
                this.$nuxt.$emit('header.state', { light: false });
            } else {
                this.$nuxt.$emit('header.state', { light: true });
            }

            const root = document.documentElement;
            if (this.$refs.navigation) {
                root.style.setProperty('--stage-homepage-navigation-height', `${this.$refs.navigation.getBoundingClientRect().height}px`);
            }
        },
    },
    beforeDestroy() {
        // Cleanup event listeners
        ['scroll', 'resize'].forEach((eventName) => {
            window.removeEventListener(eventName, this.handleScrollPos);
        });

        // Destroy Swiper instance
        if (this.stageSwiper) {
            this.stageSwiper.destroy();
        }

        // Clean up video elements
        this.items.forEach((item, index) => {
            if (item.video) {
                const video = this.getVideoElement(index);
                if (video) {
                    video.pause();
                    video.removeAttribute('src');
                    video.load();
                }
            }
        });
    },
};
